import React from 'react';
import styled from 'styled-components';
import { useAppSelector, useAppDispatch } from '../../../store';
import { DataElementContext } from '../../../page-components/common/DataElementContext';
import ClientAPI from '@/modules/casino/ClientAPI/ClientAPI';
import axios from 'axios';
import { processComponentProps } from '@/page-components/utils/processComponentProps';
import DefaultAvatar from '../_common/assets/img-avatar-male.png';
import {
  Client,
  enableCache,
  API_REGIONS,
  UserRepository,
  MessageRepository,
  ChannelRepository,
  CommunityRepository,
  PostRepository,
  subscribeTopic,
  getCommunityTopic,
  SubscriptionLevels,
  VERSION,
} from '@amityco/ts-sdk';

import './index.scss';
import LOG from './utils/Log';
import { updateSocialHubStatus } from '@/store/slices/social';

// remember joined channels
let amityJoinedChannels: any = {};
let ajc: any;
if ((ajc = window.localStorage.getItem('amityJoinedChannels')) !== null) {
  amityJoinedChannels = JSON.parse(ajc);
}

const regions: { [key: string]: string } = {
  eu: API_REGIONS.EU,
};

const AMITY_API_KEY = window.config.AMITY_API_KEY;
const AMITY_API_REGION = regions[window.config.AMITY_API_REGION];
const AMITY_CHANNEL_ID = window.config.AMITY_MAIN_CHANNEL_ID;
const AMITY_COMMUNITY_ID = window.config.AMITY_MAIN_COMMUNITY_ID;

type SocialHubProps = {
  children: any;
  styleText: string;
  className: string;
  properties?: {
    dsType: string;
    defaultTabOpened: string;
  };
};

const defaultProps = {
  className: '',
  styleText: '',
  properties: {
    dsType: '',
  },
};

enum TABS {
  CHAT = 'chat',
  FEED = 'feed',
}

export enum PostType {
  TEXT = 'text',
  IMAGE = 'image',
  VIDEO = 'video',
}

export enum SocialCapability {
  CHAT_CAN_VIEW = 'CHAT_CAN_VIEW',
  CHAT_CAN_MESSAGE = 'CHAT_CAN_MESSAGE',
  FEED_CAN_VIEW = 'FEED_CAN_VIEW',
  FEED_CAN_POST_IMAGE = 'FEED_CAN_POST_IMAGE',
  FEED_CAN_POST_VIDEO = 'FEED_CAN_POST_VIDEO',
  FEED_CAN_POST_POLL = 'FEED_CAN_POST_POLL',
  FEED_CAN_COMMENT = 'FEED_CAN_COMMENT',
  FEED_CAN_SHARE_SB_TICKET = 'FEED_CAN_SHARE_SB_TICKET',
  FEED_CAN_LIVE_STREAM = 'FEED_CAN_LIVE_STREAM',
}

export type SocialHubUser = {
  isConnected: boolean;
  isProfileLoading: boolean;
  userId: string;
  displayName: string;
  avatarUrl: string;
  isGuest: boolean;
  joinedChannelId: string;
  capabilities: SocialCapability[];
};

export type PlayerData = {
  userId: string;
  avatarUrl: string; // micros
  displayName: string; // micros
  roles: string[]; // amity
  isMuted: boolean; // amity
  isBanned: boolean; // amity
};

type FeedMetadata = {
  pinned: {
    text: string;
    type: string;
    postId: string;
  };
};

export enum PinnedMessageType {
  TEXT = 'text',
  WARN = 'warning',
  POST = 'post',
}

type PinnedMessage = {
  type: PinnedMessageType;
  text: string;
  postId: string;
  onPostTypeMessageClick: () => void;
};

// -------------------------

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

// Only required to do once in the lifetime of the application
const client = Client.createClient(AMITY_API_KEY, AMITY_API_REGION);
// wipeCache();
enableCache();

// Client.onNetworkActivities((request, response) => {
//   console.log('🐞request', request);
//   console.log('🪲response', response);
// });

const sessionHandler: Amity.SessionHandler = {
  sessionWillRenewAccessToken(renewal: Amity.AccessTokenRenewal) {
    // for details on other renewal methods check session handler
    renewal.renew();
  },
};

const login = (userId: string, displayName: string, avatarCustomUrl?: string) => {
  LOG.warn('VERSION:', VERSION);
  LOG('LOGGING IN TO AMITY...');
  LOG.info('userId:', userId);
  LOG.info('nickname:', displayName);
  LOG.info('avatar:', avatarCustomUrl);

  const axios = ClientAPI.getInstance();
  const request = axios({
    url: '/api/social-hub/amity-auth',
    method: 'post',
    data: {
      userId,
    },
  });

  request
    .then((response) => {
      // @ts-ignore
      if (response?.status === 'OK' && response?.result) {
        // @ts-ignore
        const authToken = response.result;

        Client.login(
          {
            userId,
            displayName,
            authToken,
          },
          sessionHandler,
        ).then((success) => {
          if (!success) {
            LOG.error('ERROR LOGGING IN');
            return;
          }

          LOG.ok('LOGGED IN', success);

          // Update user's avatar
          // if (avatarCustomUrl) {
          //   LOG('UPDATING AVATAR...');

          //   UserRepository.updateUser(userId, {
          //     avatarCustomUrl,
          //   }).then((user) => {
          //     LOG.ok('UPDATED AVATAR', user);
          //   });
          // }
        });
      }
    })
    .catch((error) => {
      LOG.error(error);
    });
};

const joinCommunity = (accessToken: string, communityId: string, userId: string, callback: any) => {
  LOG.warn('VERSION:', VERSION);
  LOG(`JOIN IN TO COMMUNITY... ${communityId}`);
  LOG.info('userId:', userId);

  axios({
    headers: {
      Authorization: 'Bearer ' + accessToken,
    },
    url: window.config.playerApiUrl + '/player/data/join-amity-community',
    method: 'get',
  })
    .then((response: any) => {
      callback?.(response?.data);
    })
    .catch((error) => {
      LOG.error(error);
      callback?.(null);
    });
};

export const updatePlayerData = async (
  access_token: any,
  userIds: string[],
  setPlayerData: React.Dispatch<React.SetStateAction<PlayerData[]>>,
  existingPlayerData: PlayerData[],
) => {
  try {
    const newUserIds = Array.from(new Set(userIds)).filter((id) => !existingPlayerData.some((p) => p.userId === id));

    if (newUserIds.length === 0) return;

    const response = await axios({
      headers: {
        Authorization: 'Bearer ' + access_token,
      },
      url: window.config.playerApiUrl + '/player/data/players',
      method: 'post',
      data: { player_ids: newUserIds },
    });

    LOG.info('RESPONSE', response);

    if (response.status === 200 && response.data) {
      const newUsers = response.data.map((player: any) => {
        return {
          userId: player.player_id,
          displayName: player.nickname,
          avatarUrl: player.avatar.url || DefaultAvatar,
          roles: [],
          isMuted: false,
          isBanned: false,
        };
      });

      const systemPlayerIds = newUserIds.filter((id) => !newUsers.some((player: any) => player.userId === id));
      LOG.info('SYSTEM PLAYER IDS', systemPlayerIds);

      if (systemPlayerIds.length) {
        const users = await UserRepository.getUserByIds(systemPlayerIds);
        LOG.info('AMITY USERS', users.data);

        const systemUsers = users.data.map((user) => {
          return {
            userId: user.userId,
            displayName: user.displayName ?? '',
            avatarUrl: window.config.socialDefaultSystemUserAvatar || DefaultAvatar,
            roles: user.roles,
            isMuted: false,
            isBanned: false,
          };
        });

        if (systemUsers.length + newUsers.length) {
          setPlayerData((prev) => {
            const allUsers = [...prev, ...newUsers, ...systemUsers];
            const uniqueUsers = Array.from(new Set(allUsers.map((user) => user.userId))).map((id) =>
              allUsers.find((user) => user.userId === id),
            );
            return uniqueUsers;
          });
        }
      } else {
        if (newUsers.length) {
          setPlayerData((prev) => {
            const allUsers = [...prev, ...newUsers];
            const uniqueUsers = Array.from(new Set(allUsers.map((user) => user.userId))).map((id) =>
              allUsers.find((user) => user.userId === id),
            );
            return uniqueUsers;
          });
        }
      }
    }
  } catch (error) {
    LOG.error(error);
  }
};

const SocialHub = (componentProps: SocialHubProps) => {
  let props = componentProps;

  const dataElementContext = React.useContext(DataElementContext);
  [props] = processComponentProps(props, dataElementContext);

  const dispatch = useAppDispatch();
  const access_token = useAppSelector((state) => state.authentication.access_token);
  const profile = useAppSelector((state) => state.profile);
  const nickname = useAppSelector((state) =>
    state.social.nickname?.approved
      ? state.social.nickname.nickname ?? ''
      : state.social.nickname?.default_nickname ?? '',
  );
  const avatar = useAppSelector((state) => state.social.avatar);
  const socialCapabilities = useAppSelector((state) => state.social.flags?.social_capabilities ?? []);
  const socialFlags = useAppSelector((state) => state.social.flags?.social_flags ?? []);

  const defaultTabOpened =
    props.properties?.defaultTabOpened === TABS.CHAT && socialCapabilities.includes(SocialCapability.CHAT_CAN_VIEW)
      ? TABS.CHAT
      : props.properties?.defaultTabOpened === TABS.FEED && socialCapabilities.includes(SocialCapability.FEED_CAN_VIEW)
        ? TABS.FEED
        : socialCapabilities.includes(SocialCapability.CHAT_CAN_VIEW)
          ? TABS.CHAT
          : socialCapabilities.includes(SocialCapability.FEED_CAN_VIEW)
            ? TABS.FEED
            : '';

  const [selectedTab, setSelectedTab] = React.useState(defaultTabOpened);
  const [oldTab, setOldTab] = React.useState(defaultTabOpened);
  const [selectedNewPostType, setSelectedNewPostType] = React.useState(PostType.TEXT);
  const [newPostSubmissionSuccess, setNewPostSubmissionSuccess] = React.useState(false);
  const [sessionState, setSessionState] = React.useState<Amity.SessionStates>(client.sessionState);
  const [joinedChannelId, setJoinedChannelId] = React.useState('');
  const [playerData, setPlayerData] = React.useState<PlayerData[]>([]);
  const [channelMessagesPre, setChannelMessagesPre] = React.useState<Amity.LiveCollection<Amity.Message>>();
  const [channelMessages, setChannelMessages] = React.useState<Amity.LiveCollection<Amity.Message>>();
  const [isFeedJoined, setIsFeedJoined] = React.useState(false);
  const [posts, setPosts] = React.useState<Amity.LiveCollection<Amity.Post>>();
  const postsNextPageFn = React.useRef<() => void | undefined>();
  const [postsHasNextPage, setPostsHasNextPage] = React.useState(false);
  const membersNextPageFn = React.useRef<() => void | undefined>();
  const [membersHasNextPage, setMembersHasNextPage] = React.useState(false);
  const [selectedPostId, setSelectedPostId] = React.useState('');
  const [feedMetadata, setFeedMetadata] = React.useState<FeedMetadata>();
  const [pinnedMessage, setPinnedMessage] = React.useState<PinnedMessage>();

  const selectedBetsNumber = useAppSelector(
    (state) =>
      (state.bets.betsSlip.tickets[state.bets.betsSlip.currentTicket].live.selected?.length ?? 0) +
      (state.bets.betsSlip.tickets[state.bets.betsSlip.currentTicket].prematch.selected?.length ?? 0),
  );
  const [betSlip, setBetSlip] = React.useState<any>({
    old: selectedBetsNumber,
    new: selectedBetsNumber,
    showTooltip: false,
  });

  React.useEffect(() => {
    setBetSlip((prev: any) => {
      const oldValue = prev.new;
      const newValue = selectedBetsNumber;

      if (oldValue === 0 && newValue > 0) {
        setTimeout(() => {
          setBetSlip((prev: any) => {
            return { ...prev, showTooltip: false };
          });
        }, 3000);
      }

      return {
        old: oldValue,
        new: newValue,
        showTooltip: oldValue === 0 && newValue > 0,
      };
    });
  }, [selectedBetsNumber]);

  const currentUser: SocialHubUser = React.useMemo(() => {
    const userId = profile.client_player_id ? `${window.config.clientId}_${profile.client_player_id}` : 'guest';
    return {
      isConnected: sessionState === 'established' && Client.getActiveUser().userId == userId,
      isProfileLoading: profile.loading,
      userId,
      displayName: nickname,
      avatarUrl: avatar?.url || DefaultAvatar,
      isGuest: userId == 'guest',
      joinedChannelId,
      capabilities: socialCapabilities as SocialCapability[],
    };
  }, [
    avatar?.url,
    socialCapabilities,
    joinedChannelId,
    nickname,
    profile.client_player_id,
    profile.loading,
    sessionState,
  ]);

  const canViewChat = currentUser.capabilities.includes(SocialCapability.CHAT_CAN_VIEW);
  const canViewFeed = currentUser.capabilities.includes(SocialCapability.FEED_CAN_VIEW);
  const canCommentOnFeed = currentUser.capabilities.includes(SocialCapability.FEED_CAN_COMMENT);
  const canPostOnFeed =
    currentUser.capabilities.includes(SocialCapability.FEED_CAN_POST_IMAGE) ||
    currentUser.capabilities.includes(SocialCapability.FEED_CAN_POST_VIDEO);
  // currentUser.capabilities.includes(SocialCapability.FEED_CAN_POST_POLL);

  //# Detect session state changes on Amity client instance
  React.useEffect(() => {
    return Client.onSessionStateChange((state: Amity.SessionStates) => {
      setSessionState(state);
      LOG.info('SESSION STATE CHANGED:', state);
    });
  }, [currentUser.userId, sessionState]);

  //# Detect user changes for re-authentication
  React.useEffect(() => {
    if (
      currentUser.isProfileLoading ||
      currentUser.isConnected ||
      sessionState === 'establishing' ||
      currentUser.isGuest
    )
      return;
    login(currentUser.userId, currentUser.displayName, currentUser.avatarUrl);
  }, [currentUser, sessionState]);

  const onChangeTab = React.useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      if (e.currentTarget.dataset.tab != null && e.currentTarget.dataset.tab !== '') {
        const tab = e.currentTarget.dataset.tab as TABS;
        setOldTab(selectedTab);
        setSelectedTab(tab);
        setSelectedPostId('');
      }
    },
    [selectedTab],
  );

  //#--------------------------- CHAT ---------------------------

  //# Join Amity channels
  React.useEffect(() => {
    if (!currentUser.isConnected) return;

    const amityKey = `chat_${currentUser.userId}`;

    if (amityJoinedChannels[amityKey] && amityJoinedChannels[amityKey] === AMITY_CHANNEL_ID) {
      LOG('ALREADY JOINED CHANNEL', AMITY_CHANNEL_ID);
      setJoinedChannelId(AMITY_CHANNEL_ID);
      return;
    }

    LOG('JOINING CHANNEL...');

    // Join main public channel (for chat messages)
    ChannelRepository.joinChannel(AMITY_CHANNEL_ID).then((isJoined) => {
      LOG.ok('JOINED CHANNEL', isJoined);
      setJoinedChannelId(AMITY_CHANNEL_ID);

      amityJoinedChannels[amityKey] = AMITY_CHANNEL_ID;
      window.localStorage.setItem('amityJoinedChannels', JSON.stringify(amityJoinedChannels));
    });
  }, [currentUser.isConnected]);

  //# Channel member info sync
  React.useEffect(() => {
    if (!joinedChannelId) return;

    // Get the data from amity specifying which users are muted/baned
    LOG('🔴 [CHAT] SUBSCRIBING TO MEMBERS LIST...');
    const unsubscribe = ChannelRepository.Membership.getMembers(
      { channelId: AMITY_CHANNEL_ID, memberships: ['muted', 'banned'], limit: 100 },
      (members) => {
        members.loading
          ? LOG('LOADING MUTED/BANNED MEMBER INFO...')
          : LOG.ok('MUTED/BANNED MEMBER INFO LOADED', members);
        if (members.loading) return;

        setMembersHasNextPage(members.hasNextPage ?? false);
        membersNextPageFn.current = members.onNextPage;
      },
    );

    return () => {
      LOG('⭕ [CHAT] UNSUBSCRIBING FROM MEMBERS LIST...');
      unsubscribe();
    };
  }, [joinedChannelId]);

  //# Member list pagination
  React.useEffect(() => {
    if (!membersHasNextPage || !membersNextPageFn.current) return;
    membersNextPageFn.current?.();
  }, [membersHasNextPage, playerData]);

  //# Messages sync
  React.useEffect(() => {
    if (!joinedChannelId) return;

    LOG('🔴 [CHAT] SUBSCRIBING TO MESSAGES LIST...');
    const unsubscribe = MessageRepository.getMessages(
      { subChannelId: AMITY_CHANNEL_ID, limit: window.config.socialChatMessageFetchLimit },
      (messages) => {
        messages.loading ? LOG('LOADING MESSAGES...') : LOG.ok('MESSAGES LOADED', messages);
        setChannelMessagesPre(messages);
      },
    );

    return () => {
      LOG('⭕ [CHAT] UNSUBSCRIBING FROM MESSAGES LIST...');
      unsubscribe();
    };
  }, [joinedChannelId]);

  //# Messages user data
  React.useEffect(() => {
    if (channelMessagesPre === undefined || !channelMessagesPre?.data) return;

    // const newMembers = Array.from(new Set(channelMessagesPre.data.map((message) => message.creatorId))).filter(
    //   (id) => !playerData.some((member) => member.userId === id),
    // );
    const memberIds = Array.from(new Set(channelMessagesPre.data.map((message) => message.creatorId)));
    // LOG.warn('NEW MEMBERS', newMembers);

    updatePlayerData(access_token, memberIds, setPlayerData, playerData).then(() => {
      setChannelMessages(channelMessagesPre);
    });
  }, [channelMessages, channelMessagesPre, access_token]);

  //#--------------------------- FEED ---------------------------

  //# Join community
  // React.useEffect(() => {
  //   if (!currentUser.isConnected) return;

  //   const amityKey = `feed_${currentUser.userId}`;

  //   if (amityJoinedChannels[amityKey] && amityJoinedChannels[amityKey] === AMITY_COMMUNITY_ID) {
  //     LOG('ALREADY JOINED COMMUNITY', AMITY_COMMUNITY_ID);
  //     setIsFeedJoined(true);
  //     return;
  //   }

  //   LOG('JOINING COMMUNITY...');

  //   /* Add user to community using backend api; try to offset costs because this way of joining
  //    * doesn't trigger action from an inactive user (needs testing)
  //    */
  //   /*
  //   joinCommunity(
  //     access_token,
  //     AMITY_COMMUNITY_ID,
  //     currentUser.userId,
  //     ({ isJoined }: { isJoined: boolean; data: any }) => {
  //       LOG.ok('JOINED COMMUNITY', isJoined);
  //       setIsFeedJoined(isJoined);

  //       amityJoinedChannels[amityKey] = AMITY_COMMUNITY_ID;
  //       window.localStorage.setItem('amityJoinedChannels', JSON.stringify(amityJoinedChannels));
  //     },
  //   );

  //   CommunityRepository.joinCommunity(AMITY_COMMUNITY_ID).then((isJoined) => {
  //     LOG.ok('JOINED COMMUNITY', isJoined);
  //     setIsFeedJoined(isJoined);

  //     amityJoinedChannels[amityKey] = AMITY_COMMUNITY_ID;
  //     window.localStorage.setItem('amityJoinedChannels', JSON.stringify(amityJoinedChannels));
  //   });
  //   */
  // }, [currentUser.isConnected, currentUser.userId, access_token]);

  //# Feed posts sync
  React.useEffect(() => {
    if (!isFeedJoined) return;

    let unsubPostContent: Amity.Unsubscriber;

    LOG('🔴 [FEED] SUBSCRIBING TO COMMUNITY...');
    const unsubCommunity = CommunityRepository.getCommunity(AMITY_COMMUNITY_ID, (community) => {
      if (community.loading) return;
      setFeedMetadata(community.data.metadata as FeedMetadata);

      LOG('🔴 [FEED] SUBSCRIBING TO COMMUNITY TOPIC...');
      unsubPostContent = subscribeTopic(getCommunityTopic(community.data, SubscriptionLevels.POST_AND_COMMENT));
    });

    LOG('🔴 [FEED] SUBSCRIBING TO POST LIST...');
    const unsubPosts = PostRepository.getPosts(
      { targetId: AMITY_COMMUNITY_ID, targetType: 'community', limit: 10 },
      (posts) => {
        posts.loading ? LOG('LOADING POSTS...') : LOG.ok('POSTS LOADED', posts);
        if (posts.loading) return;

        // LOG.info('---- THE POSTS', posts);
        const postUsers = Array.from(new Set(posts.data.map((post) => post.creator.userId)));
        // LOG.info('POST USERS', postUsers);
        updatePlayerData(access_token, postUsers, setPlayerData, playerData).then(() => {
          setPosts(posts);
        });

        if (!posts.loading) {
          const hashParams = window.location.hash.substring(1);
          const params = new URLSearchParams(hashParams);
          const hashPostId = params.get('postId');
          setPostsHasNextPage(posts.hasNextPage ?? false);
          postsNextPageFn.current = posts.onNextPage;

          if (hashPostId) {
            LOG.info('DIRECT LINK TO SHARED POST', hashPostId);
            setSelectedTab(TABS.FEED);
            setSelectedPostId(hashPostId);
            window.history.replaceState(null, '', window.location.pathname + window.location.search);
          }
        }
      },
    );

    // Subscribe to post updates

    return () => {
      LOG('⭕ [FEED] UNSUBSCRIBING FROM COMMUNITY...');
      unsubCommunity();
      LOG('⭕ [FEED] UNSUBSCRIBING FROM POST LIST...');
      unsubPosts();
      LOG('⭕ [FEED] UNSUBSCRIBING FROM COMMUNITY TOPIC...');
      unsubPostContent();
    };
  }, [access_token, isFeedJoined, playerData]);

  //# Load pinned/announcement post
  React.useEffect(() => {
    if (!currentUser.isConnected) return;

    // Set the info or warning pinned message from the feed metadata
    if (feedMetadata?.pinned) {
      if (feedMetadata.pinned.type === PinnedMessageType.TEXT || feedMetadata.pinned.type === PinnedMessageType.WARN) {
        setPinnedMessage({
          type: feedMetadata.pinned.type as PinnedMessageType,
          text: feedMetadata.pinned.text,
          postId: feedMetadata.pinned.postId,
          onPostTypeMessageClick: () => {
            setSelectedTab(TABS.FEED);
            setSelectedPostId(feedMetadata.pinned.postId);
          },
        });

        // Stop here, don't load the pinned post
        return;
      }
    }

    PostRepository.getPinnedPosts({ communityId: AMITY_COMMUNITY_ID, placement: 'announcement' }, (pinnedPosts) => {
      pinnedPosts.loading ? LOG('LOADING PINNED POSTS...') : LOG.ok('PINNED POSTS LOADED', pinnedPosts);

      if (pinnedPosts.loading || !pinnedPosts.data.length) return;

      const topPinnedPost = pinnedPosts.data[0].post;

      setPinnedMessage({
        type: PinnedMessageType.POST,
        text:
          topPinnedPost.data.type === 'sports_ticket_shared' ? topPinnedPost.data.post_text : topPinnedPost.data.text,
        postId: topPinnedPost.postId,
        onPostTypeMessageClick: () => {
          setSelectedTab(TABS.FEED);
          setSelectedPostId(topPinnedPost.postId);
        },
      });

      updatePlayerData(access_token, [topPinnedPost.creator.userId], setPlayerData, playerData);
    });
  }, [currentUser.isConnected, feedMetadata?.pinned, access_token]);

  //# Posts lazy loading (when scrolling to the bottom of the feed)
  const lastPostRef = React.useRef<HTMLElement>();
  React.useEffect(() => {
    if (selectedPostId) return;

    const els = document.querySelectorAll('.wl-social-hub-feed-post');
    if (!els.length) return;
    lastPostRef.current = els[els.length - 1] as HTMLElement;

    const options = {
      rootMargin: '0px',
      threshold: 0.5,
    };

    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting && postsHasNextPage) {
        LOG.info('LAZY LOADING MORE POSTS...', entry);
        postsNextPageFn.current?.();
      }
    }, options);

    if (lastPostRef.current) {
      observer.observe(lastPostRef.current);
    }

    return () => {
      if (lastPostRef.current) {
        observer.unobserve(lastPostRef.current);
      }
    };
  }, [selectedTab, postsNextPageFn, posts, postsHasNextPage, selectedPostId]);

  React.useEffect(() => {
    dispatch(updateSocialHubStatus({ isOpen: true }));
    return () => {
      dispatch(updateSocialHubStatus({ isOpen: false }));
    };
  }, []);

  //--------------------------- END ---------------------------

  const contextValue = React.useMemo(() => {
    return {
      oldTab,
      selectedTab,
      setSelectedTab,
      onChangeTab,
      currentUser,
      pinnedMessage,
      canViewChat,
      canViewFeed,
      canPostOnFeed,

      // Chat
      playerData,
      channelMessages,

      // Feed
      posts:
        posts?.data.map((post) => {
          return {
            ...post,
            canCommentOnFeed,
          };
        }) || [],
      selectedPostId,
      setSelectedPostId,
      selectedNewPostType,
      setSelectedNewPostType,
      newPostSubmissionSuccess,
      setNewPostSubmissionSuccess,
      setPinnedMessage,
      setPlayerData,

      betSlipSelectedOld: betSlip.old,
      betSlipSelectedNew: betSlip.new,
      betSlipShowTooltip: betSlip.showTooltip,
    };
  }, [
    oldTab,
    selectedTab,
    onChangeTab,
    currentUser,
    pinnedMessage,
    canViewChat,
    canViewFeed,
    canPostOnFeed,
    canCommentOnFeed,
    playerData,
    channelMessages,
    posts?.data,
    selectedPostId,
    betSlip,
    selectedNewPostType,
    newPostSubmissionSuccess,
  ]);
  // console.log('SocialHub[contextValue]', contextValue);

  return (
    <ModuleElementDiv className={props.className ?? ''} $styleText={props.styleText}>
      <DataElementContext.Provider value={contextValue}>{componentProps.children}</DataElementContext.Provider>
    </ModuleElementDiv>
  );
};

export default SocialHub;
