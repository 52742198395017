import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { useAppSelector, useAppDispatch } from '../../../store';
import { DataElementContext } from '../../../page-components/common/DataElementContext';
import { processComponentProps } from '@/page-components/utils/processComponentProps';
import { subscribe, unsubscribe } from '@/modules/live-casino/store/actions/app';
import PragmaticLive from '@/store/sagas/pragmaticLiveWS';

//import './index.scss';

type LiveStatsProps = {
  children: any;
  styleText: string;
  className: string;
  properties?: any;
};

const localToRemoteId: { [id: string]: string } = {
  '798': '1301',
  '1893': '1320',
  '1894': '2201',
  '1937': '230',
  '1938': '201',
  '1939': '227',
  '1940': '206',
  '1941': '233',
  '1942': '229',
  '1943': '545',
  '1944': '203',
  '1945': '205',
  '1946': '102',
  '1947': '225',
  '1948': '266',
  '1949': '204',
  '1950': '376',
  '1951': '375',
  '1952': '374',
  '1953': '373',
  '1954': '372',
  '1955': '350',
  '1956': '351',
  '1957': '352',
  '1958': '355',
  '1959': '791',
  '1960': '790',
  '1961': '789',
  '1962': '787',
  '1963': '771',
  '1964': '769',
  '1965': '768',
  '1966': '767',
  '1967': '778',
  '1968': '777',
  '1969': '776',
  '1970': '369',
  '1971': '570',
  '1972': '569',
  '1973': '554',
  '1974': '553',
  '1975': '552',
  '1976': '551',
  '1977': '561',
  '1978': '560',
  '1979': '559',
  '1980': '558',
  '1981': '557',
  '1982': '556',
  '1983': '555',
  '1984': '565',
  '1985': '564',
  '1986': '563',
  '1987': '562',
  '1988': '544',
  '1989': '543',
  '1990': '542',
  '1991': '541',
  '1992': '540',
  '1993': '535',
  '1994': '536',
  '1995': '537',
  '1996': '538',
  '1997': '539',
  '1998': '530',
  '1999': '529',
  '2000': '528',
  '2001': '527',
  '2002': '526',
  '2003': '525',
  '2004': '524',
  '2005': '523',
  '2006': '522',
  '2007': '521',
  '2008': '520',
  '2009': '519',
  '2010': '517',
  '2011': '516',
  '2012': '518',
  '2013': '515',
  '2014': '514',
  '2015': '513',
  '2016': '512',
  '2017': '511',
  '2018': '305',
  '2019': '304',
  '2020': '303',
  '2021': '302',
  '2022': '301',
  '2273': '792',
  '2274': '211a1',
  '2275': '210',
  '2276': '442',
  '2277': '226',
  '2278': '434',
  '2279': '1001',
  '2280': '901',
  '2281': '902',
  '2282': '433',
  '2283': '439',
  '2284': '435',
  '2285': '427',
  '2286': '405',
  '2287': '438',
  '2288': '421',
  '2289': '424',
  '2290': '428',
  '2291': '430',
  '2292': '432',
  '2293': '431',
  '2294': '415',
  '2295': '414',
  '2296': '412',
  '2297': '403',
  '2298': '402',
  '2299': '104',
  '2300': '436',
  '2301': '422',
  '2302': '426',
  '2303': '425',
  '2304': '413',
  '2305': '411',
  '2306': '404',
  '2307': '401',
  '2308': '381',
  '2309': '380',
  '2310': '379',
  '2311': '378',
  '2312': '377',
  '2313': '786',
  '2314': '785',
  '2315': '784',
  '2316': '788',
  '2317': '775',
  '2318': '774',
  '2319': '773',
  '2320': '772',
  '2321': '770',
  '2322': '781',
  '2323': '780',
  '2324': '779',
  '2325': '371',
  '2326': '370',
  '2327': '568',
  '2328': '567',
  '2329': '566',
  '2330': '3001',
  '2331': '3002',
  '2332': '3003',
  '2333': '3004',
  '2334': '3005',
  '2335': '3006',
  '2336': '3007',
  '2337': '3008',
  '2338': '3009',
  '2339': '3010',
  '2340': '3046',
  '2341': '3047',
  '2342': '3048',
  '2343': '3014',
  '2344': '3015',
  '2345': '3016',
  '2346': '3017',
  '2347': '3018',
  '2348': '3242',
  '2349': '3241',
  '2350': '3240',
  '2351': '3239',
  '2352': '3238',
  '2353': '3235',
  '2354': '3234',
  '2355': '3233',
  '2356': '3232',
  '2357': '3231',
  '2358': '3055',
  '2359': '3054',
  '2360': '3053',
  '2361': '3052',
  '2362': '3051',
  '2363': '3025',
  '2364': '3024',
  '2365': '3050',
  '2366': '3049',
  '2367': '3021',
  '2368': '3020',
  '2369': '3019',
  '2370': '3155',
  '2371': '103',
  '2526': '421t',
  '2527': '313',
  '2528': '233a5',
  '2529': '315',
  '2530': '314',
  '2531': '210a2',
  '2532': '230a1',
  '2533': '3156',
  '2534': '3157',
  '2535': '3158',
  '2536': '3159',
};

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

const colors: { [key: string]: string } = {
  // generate all roulette colors by number
  '0': 'green',
  '1': 'red',
  '2': 'black',
  '3': 'red',
  '4': 'black',
  '5': 'red',
  '6': 'black',
  '7': 'red',
  '8': 'black',
  '9': 'red',
  '10': 'black',
  '11': 'black',
  '12': 'red',
  '13': 'black',
  '14': 'red',
  '15': 'black',
  '16': 'red',
  '17': 'black',
  '18': 'red',
  '19': 'red',
  '20': 'black',
  '21': 'red',
  '22': 'black',
  '23': 'red',
  '24': 'black',
  '25': 'red',
  '26': 'black',
  '27': 'red',
  '28': 'black',
  '29': 'black',
  '30': 'red',
  '31': 'black',
  '32': 'red',
  '33': 'black',
  '34': 'red',
  '35': 'black',
  '36': 'red',
};

const buildStats = (stats: any, providerId: string, currency: string) => {
  if (providerId === '7') {
    return {
      seatedPlayers: stats?.totalSeatedPlayers,
      maxPlayers: stats?.tableLimits?.maxPlayers ?? 0,
      tableType: stats?.tableType ?? '',
      dealer: stats?.dealer?.name ?? '',
      currency: stats?.currency,
      minBet: stats?.tableLimits?.minBet,
      maxBet: stats?.tableLimits?.maxBet,
      lastRouletteResults:
        stats?.tableType === 'ROULETTE' && stats?.last20Results
          ? stats?.last20Results.map((e: any) => ({ result: e.result, color: e.color }))
          : [],
      image: stats?.tableImage ?? '',
    };
  } else if (providerId === '8') {
    return {
      seatedPlayers: stats?.players,
      maxPlayers: stats?.tableLimits?.maxPlayers ?? 0,
      tableType: stats?.gameType?.toUpperCase() ?? '',
      dealer: stats?.dealer?.name ?? '',
      currency: currency,
      minBet: stats?.betLimits?.[currency.toUpperCase()]?.min,
      maxBet: stats?.betLimits?.[currency.toUpperCase()]?.max,
      lastRouletteResults:
        stats?.gameType === 'Roulette' && stats?.results
          ? stats?.results.map((e: any) => ({ result: e, color: colors[e] }))
          : [],
      image: stats?.videoSnapshot?.thumbnails?.M ?? '',
    };
  }
};

const LiveStats = (componentProps: LiveStatsProps) => {
  let props = componentProps;

  const dataElementContext = React.useContext(DataElementContext);
  [props] = processComponentProps(props, dataElementContext);

  const provider_id = dataElementContext?.provider_id;
  const id = dataElementContext?.id;
  const dispatch = useAppDispatch();
  const tables = useAppSelector((state) => state.pragmaticLive.tables);
  const evoTables: any = useAppSelector((state) => state.liveCasino.tables?.state?.tables);

  React.useEffect(() => {
    if (provider_id === '7' && id && localToRemoteId[id]) {
      PragmaticLive.subscribe(localToRemoteId[id], 'RON');
    }

    if (provider_id === '8' && id) {
      dispatch(subscribe());
    }

    return () => {
      if (provider_id === '7' && id && localToRemoteId[id]) {
        PragmaticLive.unsubscribe(localToRemoteId[id]);
      }
      if (provider_id === '8' && id) {
        dispatch(unsubscribe());
      }
    };
  }, [provider_id, id]);

  const contextValue = React.useMemo(() => {
    let stats: any = {};
    if (provider_id === '7' && id && localToRemoteId[id] && tables[localToRemoteId[id]]) {
      stats = buildStats(tables[localToRemoteId[id]], provider_id, window.config.defaultCurrency);
    } else if (provider_id === '8' && id) {
      const [gameType, tableId] = id.split('/');
      if (evoTables?.[tableId]) {
        stats = buildStats(evoTables?.[tableId], provider_id, window.config.defaultCurrency);
      }
    }

    /*
      tableType: "ROULETTE"
      tableType: "BACCARAT"
      tableType: "BLACKJACK"
    */

    return {
      ...dataElementContext,
      stats: stats,
    };
  }, [dataElementContext, componentProps, provider_id, id, tables, evoTables]);

  //console.log('contextValue', contextValue);

  return (
    <ModuleElementDiv className={componentProps.className ?? ''} $styleText={componentProps.styleText}>
      <DataElementContext.Provider value={contextValue}>{componentProps.children}</DataElementContext.Provider>
    </ModuleElementDiv>
  );
};

export default LiveStats;
