import React from 'react';
import styled from 'styled-components';
import { useInView } from 'react-intersection-observer';
import { uniqueId } from 'lodash-es';
import { useTranslation } from 'react-i18next';

import './DataCarousel.scss';
import { useAppSelector } from '../../store';
import { sortSources } from '../utils/sortSource';
import { DataElementContext } from '../common/DataElementContext';
import craftJsParser from '../../components/utils/craftJsParser';
import { PageDataContext, getProjectDataFromContext } from '../../components/utils/PageDataProvider';
import evBus from '../../utils/evbus';
import LinkedComponent from '../../components/linked-component';
import ErrorBoundary from './ErrorBoundary';

import { processComponentProps } from '@/page-components/utils/processComponentProps';

import { Splide, SplideSlide } from './react-splide';
import { Grid } from '@splidejs/splide-extension-grid';
import { useProcessList } from '../utils/useProcessList';
import { Slide } from './lib/transitions/Slide/Slide';
import { Fade } from './lib/transitions/Fade/Fade';
import { useMediaQueries, useMediaQuery } from '@/components/utils/useQueryMedia';

import { logCarouselEvent } from '@/analytics';

export const defaultProps = {
  className: '',
  styleText: '',
  properties: {
    slidesNo: 3,

    autoplay: true,
    interval: 5000,
    drag: false,
    arrows: true,
    pagination: false,
    gap: '8px',
    padding: '16px',
    focus: 'center',
    trimSpace: true,
    carouselType: 'loop',
    rewind: true,
    speed: 400,
    rewindSpeed: 1000,
    rewindByDrag: false,
    width: '100%',
    height: '300px',
    fixedWidth: null,
    fixedHeight: null,
    heightRatio: null,
    autoWidth: false,
    autoHeight: false,
    start: 0,
    perPage: 1,
    perMove: 1,

    dsType: '',
    dsId: '',
    useCmpRepeater: false,
    cmId: null,
    repeater: null,
  },
};

const DataCarouselDiv = styled.div((props) => props.$styleText);

export const DataCarousel = (componentProps) => {
  let props = componentProps;

  const key = React.useRef(uniqueId('unique-carousel-'));
  const index = React.useRef(0);
  const externalIndex = React.useRef(0);

  let slideAR = props.properties.slideAR;
  const valueClasses = {
    1.6: 'ar-1_6',
    '1/1': 'ar-11',
    '1/2': 'ar-12',
    2.35: 'ar-2_35',
    '2/1': 'ar-21',
    '2/3': 'ar-23',
    '3/1': 'ar-31',
    '3/2': 'ar-32',
    '4/5': 'ar-45',
  };

  const { i18n } = useTranslation();
  const components = useAppSelector((state) => state.templatesConfig.components);
  const dataSources = useAppSelector((state) => state.dataSources.items);
  const tmplVersion = useAppSelector((state) => state.templatesConfig.version);
  const profile = useAppSelector((state) => state.profile);
  const [refresh, setRefresh] = React.useState(0);

  // Flag to differentiate between user swipe and autoplay
  var isUserInteraction = false;

  // Keep track of the previous index to determine swiped slide
  var previousIndex = 0;

  const pageDataContext = React.useContext(PageDataContext);

  const ref = React.useRef();
  const mounted = React.useRef(false);
  const {
    ref: rootRef,
    inView,
    entry,
  } = useInView({
    /* Optional options */
    threshold: 0,
    root: document,
    rootMargin: `${window.screen.availHeight}px 0px ${window.screen.availHeight}px 0px`,
    triggerOnce: true,
  });
  //const [extraClassName, setExtraClassName] = React.useState('');

  const dataElementContext = React.useContext(DataElementContext);
  let isVisible = true;

  [props, isVisible] = processComponentProps(props, dataElementContext);

  const { properties } = props;
  const { dsId, useCmpRepeater, cmId, repeater } = props.properties;
  const dataElementTypeId = dataSources?.[dsId]?.element_type?.type_id;

  const tid = React.useRef(0);
  const handleOnError = (error, errorInfo) => {
    clearTimeout(tid.current);
    isVisible = false;
    tid.current = setTimeout(() => {
      if (mounted.current) {
        isVisible = true;
        index.current += 1;
        setRefresh((prev) => prev + 1);
        //console.log('refresh');
      }
    }, 1000);
  };

  React.useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;

      const div = document.querySelector(`div[data-id="${key.current}"]`);
      div?.classList?.remove('is-overflow-left', 'is-overflow-right', 'is-overflow-both');
    };
  }, []);

  const handleArrowsMounted = (splide, prevArrow, nextArrow) => {
    if (!splide?.options || !prevArrow || !nextArrow) return;

    [prevArrow, nextArrow].forEach((arrow) => {
      arrow.addEventListener('click', () => {
        isUserInteraction = true;
      });
    });
  };

  const carouselAction = React.useCallback(
    (data) => {
      try {
        if (ref.current && properties.id === data.id) {
          if (dataElementContext && data.__id !== dataElementContext.__id) return;
          if (data.op === 'next') {
            ref.current.splide.Components.Controller.go('>');
          } else if (data.op === 'previous') {
            ref.current.splide.Components.Controller.go('<');
          } else if (data.op === 'go') {
            ref.current.splide.Components.Controller.go(data.index);
          }
        }
      } catch (err) { }
    },
    [dataElementContext, props],
  );

  React.useEffect(() => {
    evBus.on('CAROUSEL_ACTION', carouselAction);

    return () => {
      evBus.remove('CAROUSEL_ACTION', carouselAction);
    };
  }, [dataElementContext, props]);

  let slidesNo = Number(properties.slidesNo);

  let {
    data: ds,
    changed: dsChanged,
    processed: dsProcessed,
  } = useProcessList(dataSources?.[dsId], i18n.language, dsId, slidesNo);

  if (
    props.properties.externalList &&
    Array.isArray(props.properties.externalList) &&
    props.properties.externalList.length
  ) {
    ds = props.properties.externalList;
    if (externalIndex.current != ds.length) {
      externalIndex.current += 1;
      dsChanged = externalIndex.current;
    }
  }

  if (slidesNo === 0 && ds.length) {
    slidesNo = ds.length;
  }
  const handleEvent = (eventType) => (splide, slide, event) => {
    if (eventType === 'mounted') {
      if (props.properties['onMounted'] && typeof props.properties['onMounted'] === 'function') {
        props.properties['onMounted'](splide);
      }
      return;
    } else if (eventType === 'click') {
      isUserInteraction = true;

      if (!dataElementTypeId && props.className.includes('tournaments-slots-carousel')) {
        logCarouselEvent(event, eventType, ds, dsId, 'tournaments-slots-carousel', slide.index, properties);
      } else if (!dataElementTypeId && props.className.includes('recently-played-carousel')) {
        logCarouselEvent(event, eventType, ds, dsId, 'recently-played-carousel', slide.index, properties);
      } else {
        logCarouselEvent(event, eventType, ds, dsId, dataElementTypeId, slide.index, properties);
      }
      if (props.properties['onClick'] && typeof props.properties['onClick'] === 'function') {
        props.properties['onClick'](event, slide, splide);
      }
      return;
    } else if (eventType === 'active') {
      if (props.properties['onActive'] && typeof props.properties['onActive'] === 'function') {
        props.properties['onActive'](event, slide, splide);
      }
      return;
    } else if (eventType === 'moved') {
      const newIndex = slide;
      if (isUserInteraction) {
        var totalSlides2 = splide.length;

        // Get the distance between the previous and new index
        // so we can determine if the user swiped left or right
        // even if the whole carousel was wrapped around
        var forwardDistance2 = (newIndex - previousIndex + totalSlides2) % totalSlides2;
        var backwardDistance2 = (previousIndex - newIndex + totalSlides2) % totalSlides2;

        const direction = forwardDistance2 < backwardDistance2 ? 'next' : 'prev';
        const movedSlides = Math.min(forwardDistance2, backwardDistance2);

        // console.log(`${direction} swipe or arrow click (moved ${movedSlides} slides)`);

        // TODO: find a better workaround for carousels that do not have datasource defined within them
        if (!dataElementTypeId && props?.className?.includes('recommended-bets-carousel')) {
          logCarouselEvent(event, direction, ds, dsId, 'recommended-bets-carousel', previousIndex, properties);
        } else if (!dataElementTypeId && props?.className?.includes('tournaments-slots-carousel')) {
          logCarouselEvent(event, direction, ds, dsId, 'tournaments-slots-carousel', previousIndex, properties);
        } else if (!dataElementTypeId && props?.className?.includes('recently-played-carousel')) {
          logCarouselEvent(event, direction, ds, dsId, 'recently-played-carousel', previousIndex, properties);
        } else {
          logCarouselEvent(event, direction, ds, dsId, dataElementTypeId, previousIndex, properties);
        }
      }

      previousIndex = newIndex;
      isUserInteraction = false;
    } else if (eventType === 'move') {
      // const newIndex = slide;
      // if (isUserInteraction) {
      //   var totalSlides = splide.length;
      //   // Get the distance between the previous and new index
      //   // so we can determine if the user swiped left or right
      //   // even if the whole carousel was wrapped around
      //   var forwardDistance = (newIndex - previousIndex + totalSlides) % totalSlides;
      //   var backwardDistance = (previousIndex - newIndex + totalSlides) % totalSlides;
      //   const direction = forwardDistance < backwardDistance ? 'next' : 'prev';
      //   const movedSlides = Math.min(forwardDistance, backwardDistance);
      //   console.log(`${direction} swipe or arrow click (moved ${movedSlides} slides)`);
      //   logCarouselEvent(event, profile, direction, properties, previousIndex);
      // }
      // previousIndex = newIndex;
      // isUserInteraction = false;
    } else if (eventType === 'ready') {
      //console.log('DataCarousel[ready]', { splide, slide, event });
      setTimeout(() => {
        splide?.splide?.refresh?.();
      }, 100);
    }

    const isOverflow = splide.Components.Layout.isOverflow();

    const div = document.querySelector(`div[data-id="${key.current}"]`);

    if (isOverflow) {
      const perPage = splide.options.perPage ?? 1;
      const maxPages = Math.ceil(splide.length / perPage);
      const currentPage = Math.ceil((splide.index + 1) / perPage);

      if (currentPage === maxPages) {
        div?.classList?.add('is-overflow-left');
      } else if (currentPage === 1) {
        div?.classList?.add('is-overflow-right');
      } else {
        div?.classList?.add('is-overflow-both');
      }
    } else {
      div?.classList?.remove('is-overflow-left', 'is-overflow-right', 'is-overflow-both');
    }
  };

  let breakpoints = null;
  try {
    const tmp = JSON.parse(props.properties.breakpoints);
    if (tmp && typeof tmp === 'object') {
      breakpoints = tmp;
    }
  } catch (err) {
    //console.error(err);
  }

  const toBps = (obj) => {
    const result = {};
    Object.keys(obj).forEach((key) => {
      result[key] = `(min-width: ${key}px)`;
    });
    return result;
  };

  const isDesktop = useMediaQuery('(min-width: 900px)');
  const qMatches = useMediaQueries(breakpoints ? toBps(breakpoints) : {});

  Object.keys(qMatches.matches)
    .sort()
    .forEach((key) => {
      if (qMatches.matches[key]) {
        const bps = breakpoints[key];
        if (bps.slideAR) {
          slideAR = bps.slideAR;
        }
      }
    });

  const content = React.useMemo(() => {
    try {
      /*
      if (dataElementTypeId === 'bets.data.match' || dataElementTypeId === 'bets.data.tournament') {
        return null;
      }
      */

      let sorted = ds;

      if (repeater && Array.isArray(repeater.items) && repeater.items.length && ds && ds.length) {
        sorted = sortSources(ds, repeater);
      }

      let finalList = sorted?.slice(0, slidesNo);

      if (finalList.length === 0 && dsProcessed) {
        return null;
      }

      if ((finalList.length === 0 || !inView) && !props.properties.disableInViewport) {
        let howMany =
          props.properties.perPage && !isNaN(Number(props.properties.perPage)) ? Number(props.properties.perPage) : 1;
        if (props.properties.mediaQuery === 'min' && props.properties.breakpoints) {
          try {
            const tmp = JSON.parse(props.properties.grid);
            if (tmp && typeof tmp === 'object' && tmp.cols && !isNaN(tmp.cols) && !isNaN(tmp.rows)) {
              howMany = Number(tmp.cols) * Number(tmp.rows);
            } else if (
              tmp &&
              typeof tmp === 'object' &&
              Array.isArray(tmp.dimensions) &&
              tmp.dimensions[0] &&
              tmp.dimensions[0].length === 2
            ) {
              howMany = tmp.dimensions[0][0] * tmp.dimensions[0][1];
            }
          } catch (err) { }

          let breakpoints = {};
          try {
            const tmp = JSON.parse(props.properties.breakpoints);
            if (tmp && typeof tmp === 'object') {
              breakpoints = tmp;
            }
          } catch (err) { }
          const keys = Object.keys(breakpoints).sort((a, b) => Number(a) - Number(b));
          keys.reverse();
          for (let i = 0; i < keys.length; i++) {
            if (breakpoints[keys[i]] && breakpoints[keys[i]].perPage && !isNaN(Number(breakpoints[keys[i]].perPage))) {
              howMany = Number(breakpoints[keys[i]].perPage);
              break;
            } else if (
              breakpoints[keys[i]] &&
              breakpoints[keys[i]].grid &&
              !isNaN(Number(breakpoints[keys[i]].grid.cols)) &&
              !isNaN(Number(breakpoints[keys[i]].grid.rows))
            ) {
              howMany = Number(breakpoints[keys[i]].grid.rows) * Number(breakpoints[keys[i]].grid.cols);
              break;
            } else if (
              breakpoints[keys[i]] &&
              breakpoints[keys[i]].grid &&
              Array.isArray(breakpoints[keys[i]].grid.dimensions) &&
              breakpoints[keys[i]].grid.dimensions[0] &&
              breakpoints[keys[i]].grid.dimensions[0].length === 2
            ) {
              howMany = breakpoints[keys[i]].grid.dimensions[0][0] * breakpoints[keys[i]].grid.dimensions[0][1];
              break;
            }
          }
        }

        const style = {};

        if (slideAR && valueClasses[slideAR] == null) {
          style.aspectRatio = slideAR;
        }

        return [...Array(howMany).keys()].map((i) => (
          <SplideSlide key={`slide_${i}`} className="placeholder-glow">
            <div
              style={style}
              className={`skeleton-elem ${slideAR && valueClasses[slideAR] != null ? valueClasses[slideAR] : slideAR ?? ''
                } placeholder`}
            >
              &nbsp;
            </div>
          </SplideSlide>
        ));
      }

      const data = getProjectDataFromContext(pageDataContext);

      const dsWrapperId = data[props.nodeId].nodes[0];
      const dsWrapperData = data[dsWrapperId];

      let itemContent = null;

      if (dsWrapperData && dsWrapperData.nodes && dsWrapperData.nodes[0]) {
        itemContent = craftJsParser({
          craftState: data,
          rootNodeId: dsWrapperData.nodes[0],
          pageId: props.refType === 'layout' ? null : props.refId,
          pageLayoutId: props.refType === 'layout' ? props.refId : null,
          pageType: props.refType,
        });
      }

      let slideNo = 1;

      const rendered =
        finalList.map((orig, i) => {
          let item = orig;
          let tmp = itemContent;
          if (useCmpRepeater) {
            let renderCmId = cmId;
            let className = '';

            if (repeater && Array.isArray(repeater.items) && repeater.items.length) {
              let repeaterItemConfig = null;

              if (repeater.type === 'repeat_last') {
                // if in mode "repeat_last" so for every item in the list on a position bigger than the
                // repeater config list will have the config from teh last position
                if (i > repeater.items.length - 1) {
                  repeaterItemConfig = repeater.items[repeater.items.length - 1];
                } else {
                  repeaterItemConfig = repeater.items[i];
                }
              } else if (repeater.type === 'repeat_last_n') {
                const n = repeater.n;
                if (n && !isNaN(n) && n <= repeater.items.length) {
                  if (i > repeater.items.length - 1) {
                    const offset = repeater.items.length - n;
                    const pos = i % n;
                    repeaterItemConfig = repeater.items[pos + offset];
                  } else {
                    repeaterItemConfig = repeater.items[i];
                  }
                }
              } else {
                // is in mode "repeat_all", so for every item in the list on a position bigger than the
                // repeater config list will have the config from the position that will normally be when
                // repeating the entire list
                const pos = i % repeater.items.length;
                repeaterItemConfig = repeater.items[pos];
              }

              if (repeaterItemConfig && repeaterItemConfig.cmId) {
                renderCmId = repeaterItemConfig.cmId;
              }
              if (repeaterItemConfig && repeaterItemConfig.className) {
                className = repeaterItemConfig.className;
              }
            }

            if (renderCmId && components[renderCmId] != null) {
              tmp = <LinkedComponent componentId={renderCmId} className={className} />;
            }
          }

          if (!tmp) return null;

          if (Object.isFrozen(item)) {
            item = { ...item };
          }

          item.slideNo = slideNo++;
          item.slideTotal = finalList.length;
          item.parentContext = dataElementContext;
          item.isDesktop = isDesktop;
          item.dsId = dsId;

          return (
            <SplideSlide key={`slide_${i}`}>
              <DataElementContext.Provider key={i} value={item}>
                {tmp}
              </DataElementContext.Provider>
            </SplideSlide>
          );
        }) || [];

      index.current = dsChanged;

      return rendered;
    } catch (err) {
      console.error(err);
      console.log(ds);
    }

    return null;
  }, [
    dataElementTypeId,
    pageDataContext,
    props.nodeId,
    props.refId,
    props.refType,
    ds,
    dsChanged,
    dsProcessed,
    components,
    inView,
    props.properties.grid,
    slideAR,
    props.properties.extraKey,
    refresh,
    isDesktop,
  ]);

  if (content == null || content.length === 0) {
    if (dsProcessed) {
      const parent = entry?.target?.closest('.parent-scroller');
      if (parent) {
        parent.classList.add('d-none');
      }
    }
  } else {
    const parent = entry?.target?.closest('.parent-scroller');
    if (parent) {
      parent.classList.remove('d-none');
    }
  }

  let padding = props.properties.padding;
  if (padding.includes('{') && padding.includes('}')) {
    try {
      const tmp = JSON.parse(props.properties.padding);
      if (tmp && typeof tmp === 'object') {
        padding = tmp;
      }
    } catch (err) { }
  }

  let options = {
    autoplay: properties.autoplay,
    interval: properties.interval,
    drag: properties.drag,
    snap: properties.snap,
    noDrag: properties.noDrag,
    arrows: properties.arrows,
    pagination: properties.pagination,
    gap: properties.gap,
    padding: padding,
    focus: properties.focus,
    trimSpace: properties.trimSpace,
    slide: properties.slide,
    rewind: properties.rewind,
    speed: properties.speed,
    rewindSpeed: properties.rewindSpeed,
    rewindByDrag: properties.rewindByDrag,
    width: properties.width,
    height: properties.height,
    fixedWidth: properties.fixedWidth,
    fixedHeight: properties.fixedHeight,
    heightRatio: properties.heightRatio,
    autoWidth: properties.autoWidth,
    autoHeight: properties.autoHeight,
    start: properties.start,
    perPage: properties.perPage,
    perMove: properties.perMove,
    pauseOnHover: properties.pauseOnHover,
    type: properties.carouselType,
    mediaQuery: properties.mediaQuery,
    breakpoints: breakpoints,
  };

  if (properties.isNavigation) {
    options.isNavigation = true;
  }

  const extensions = {};

  let grid = null;
  try {
    const tmp = JSON.parse(props.properties.grid);
    if (tmp && typeof tmp === 'object') {
      grid = tmp;
    }
  } catch (err) { }

  if (grid && Object.keys(grid).length) {
    extensions.Grid = Grid;
    options.grid = grid;
    delete options.perPage;
    delete options.perMove;
  }

  if (ref.current) {
    const splide = ref.current.splide;

    const perPage = splide.options.perPage ?? 1;
    const maxPages = Math.ceil(splide.length / perPage);
    let changed = false;

    if (maxPages === 1 && ds.length && ds.length <= perPage) {
      if (options.padding?.left && options.padding?.right) {
        options.padding.right = options.padding.left;
        changed = true;
      }

      if (typeof options.breakpoints === 'object' && options.breakpoints != null) {
        Object.keys(options.breakpoints).forEach((key) => {
          if (options.breakpoints[key].padding && options.breakpoints[key].padding.right) {
            options.breakpoints[key].padding.right = '0px';
            changed = true;
          }
        });
      }
    }

    if (changed) {
      index.current += 1;
    }
  }

  if (!isVisible) return null;

  //console.log('DataCarousel[props]', props);

  return (
    <ErrorBoundary onError={handleOnError}>
      <DataCarouselDiv
        ref={rootRef}
        className={`page-components-data-carousel ${props.className ?? ''}`}
        $styleText={props.styleText}
        style={props.style}
        data-id={key.current}
      >
        {!(content == null || content.length === 0) && (
          <Splide
            key={`${tmplVersion}${props.overrideKey ? `-${props.overrideKey}` : ''}-${key.current}-${index.current}-${dsId}`}
            data-key={`${tmplVersion}${props.overrideKey ? `-${props.overrideKey}` : ''}-${key.current}-${index.current}-${dsId}`}
            data-ds-id={dsId ?? ''}
            ref={ref}
            extensions={{ Grid }}
            transition={options.type === 'fade' ? Fade : Slide}
            options={options}
            onMounted={handleEvent('mounted')}
            onMoved={handleEvent('moved')}
            onMove={handleEvent('move')}
            onUpdated={handleEvent('updated')}
            onReady={handleEvent('ready')}
            onClick={handleEvent('click')}
            onActive={handleEvent('active')}
            onArrowsMounted={handleArrowsMounted}
            onDrag={() => (isUserInteraction = true)}
          >
            {content}
          </Splide>
        )}
      </DataCarouselDiv>
    </ErrorBoundary>
  );
};
