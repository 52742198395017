import React from 'react';
import styled from 'styled-components';
import { useMatches } from 'react-router-dom';
import { cloneDeep } from 'lodash-es';
import moment from 'moment';

import { useTranslation } from 'react-i18next';

import { useAppSelector, useAppDispatch, store } from '../../../store';
import { DataElementContext } from '../../../page-components/common/DataElementContext';
import { fetchSources } from '../../../store/slices/dataSources';
import { getTr, formatDate, liveScore } from '../bets/utils/functions';
import { processComponentProps } from '@/page-components/utils/processComponentProps';
import {
  searchApiByQuery,
  getSearchInfo,
  cancelSearch,
  missSearch,
  SearchAPIResponse,
  hitSearch,
} from './lib/search-api';
import { saveCalendarSelection } from '../../../modules/bets/store/actions/calendar';
import { prematchFetchSportByDay } from '../../../modules/bets/store/actions/prematch';
import { useMediaQuery } from '../../utils/useQueryMedia';
import images from '../../../modules/lotto/assets/countries';

import './index.scss';
import { logSearchBarEvent } from '@/analytics';

type GeneralSearchProps = {
  children: any;
  styleText: string;
  className: string;
  properties?: any;
};

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

const GeneralSearch = (componentProps: GeneralSearchProps) => {
  let props = componentProps;

  const { i18n } = useTranslation();

  const dispatch = useAppDispatch();
  const accessToken = useAppSelector((state) => state.authentication.access_token);
  const isDesktop = useMediaQuery('(min-width:900px)');

  const dataElementContext = React.useContext(DataElementContext);
  [props] = processComponentProps(props, dataElementContext);

  const searchInput = props.properties.searchQuery ?? 'search-query';

  const [state, setState] = React.useState({
    pathname: '',
    resCasino: [],
    resMatches: [],
    resMarkets: [],
    resTournaments: [],
    resCategories: [],
    resPromotions: [],
    resLotteries: [],
    focused: false,
    searching: false,
    open: false,
    lastUUID: '',
    recentSearched: JSON.parse(localStorage.getItem('recentSearched') || '[]'),
  });
  const maxSearchItems = 10;

  const defaultUIState = {
    casino: {
      items: 9,
      showMore: false,
    },
    matches: {
      items: 5,
      showMore: false,
    },
    markets: {
      items: 5,
      showMore: false,
    },
    tournaments: {
      items: 3,
      showMore: false,
    },
    categories: {
      items: 3,
      showMore: false,
    },
    promotions: {
      items: 5,
      showMore: false,
    },
    lotteries: {
      items: 5,
      showMore: false,
    },
    sports: [],
    selectedSport: -1,
  };

  const [uiState, setUIState] = React.useState(cloneDeep(defaultUIState));
  const [query, setQuery] = React.useState('');
  const deferredQuery = React.useDeferredValue(query);
  const tid = React.useRef<any>(null);
  const matches = useMatches();

  const isHomepage = props.properties.isHomepage ?? false;

  const sourceId = 'all_slot_games_api';
  const promotionsId = 'all_promotions_api';
  React.useEffect(() => {
    if (accessToken != null) dispatch(fetchSources({ ids: [sourceId, promotionsId] }));
  }, [accessToken]);

  const toggleOpen = React.useCallback(
    (event: any) => {
      setState((v) => {
        const lastUUID = v.lastUUID;

        setTimeout(() => {
          if (!v.open) {
            document.querySelector<HTMLInputElement>(`input[name="${searchInput}"]`)?.focus();
          } else {
            if (lastUUID) {
              cancelSearch({ uuid: lastUUID });
            }
          }
        }, 100);

        const newV = {
          ...v,
          open: !v.open,
        };

        if (!newV.open) {
          newV.searching = false;
          newV.focused = false;
          newV.resCasino = [];
          newV.resMatches = [];
          newV.resMarkets = [];
          newV.resTournaments = [];
          newV.resCategories = [];
          newV.resPromotions = [];
          newV.resLotteries = [];
          newV.lastUUID = '';
        }

        logSearchBarEvent(event, newV.open ? 'open' : 'close', props, state, deferredQuery);

        return newV;
      });
    },
    [state],
  );

  React.useEffect(() => {
    if (state.open) {
      document.body.classList.add('search-opened');
    } else {
      document.body.classList.remove('search-opened');
    }
  }, [state.open]);

  const searchType = props.properties.searchType ?? 'all';

  const doSearch = React.useCallback(
    async (search: string) => {
      let isBets: boolean | string = false;
      const pathname = window.location.pathname;

      if (pathname.includes('/bets/') || pathname.includes('/winner-fun/')) {
        if (pathname.includes('/pre-match/')) {
          isBets = 'prematch';
        } else if (pathname.includes('/live-match/')) {
          isBets = 'live';
        }
      }

      const recentSearched = JSON.parse(localStorage.getItem('recentSearched') || '[]');
      if (search) {
        const exists = recentSearched.findIndex((item: any, index: number) => {
          if (search.indexOf(item) === 0 || item.indexOf(search) === 0) {
            recentSearched[index] = search;
            return true;
          }
          return false;
        });
        if (exists === -1) {
          recentSearched.unshift(search);
        } else {
          const s = recentSearched.splice(exists, 1);
          recentSearched.unshift(s[0]);
        }
      }
      localStorage.setItem('recentSearched', JSON.stringify(recentSearched.slice(0, maxSearchItems)));

      let response: { data: SearchAPIResponse } | null = null;
      try {
        response = await searchApiByQuery({ request: { query: search, language: i18n.language, limit: 50 } });
      } catch (e) {
        console.error('searchApiByQuery[error]', e);
      }

      const sports: any[] = [];
      const resMatches: any[] = [];
      const resMarkets: any[] = [];
      const resTournaments: any[] = [];
      const resCategories: any[] = [];

      if (
        (searchType === 'all' || searchType === 'bets') &&
        window.config.betsEnabled === '1' &&
        Array.isArray(response?.data?.bets?.result)
      ) {
        if (response.data?.bets && response.data?.bets.success && Array.isArray(response.data?.bets?.result)) {
          const categories: { [id: string]: boolean } = {};
          const tournaments: { [id: string]: boolean } = {};
          const markets: { [id: string]: boolean } = {};
          const matches: { [id: string]: boolean } = {};

          response.data?.bets?.result.forEach((sport) => {
            if (sport.sport) {
              sports.push(sport.sport);
            }

            sport.events?.forEach((match) => {
              const key = `${match.idSport}-${match.idCategory}-${match.idTournament}-${match.idMatch}`;
              if (matches[key]) return;
              matches[key] = true;

              resMatches.push({
                mType: match.mType,
                idSport: match.idSport,
                idCategory: match.idCategory,
                idTournament: match.idTournament,
                idMatch: match.idMatch,
                team1Name: match.team1Name,
                team2Name: match.team2Name,

                sportName: match.sportName,
                categoryName: match.categoryName,
                tournamentName: match.tournamentName,

                matchDateTimeString: formatDate(match.matchDateTime, i18n.language, false),
                // @ts-ignore
                scoreInfo: match.mType === 'live' ? liveScore(match, match.currentStatus) : {},
              });
            });

            sport.tournaments?.forEach((tournament) => {
              const key = `${tournament.idSport}-${tournament.idCategory}-${tournament.idTournament}`;
              if (tournaments[key]) return;
              tournaments[key] = true;

              resTournaments.push({
                mType: isBets === 'live' ? 'live-match' : 'pre-match',
                idSport: tournament.idSport,
                idCategory: tournament.idCategory,
                idTournament: tournament.idTournament,
                tournamentName: tournament.tournamentName,
              });
            });

            sport.categories?.forEach((category) => {
              const key = `${category.idSport}-${category.idCategory}`;
              if (categories[key]) return;
              categories[key] = true;

              resCategories.push({
                mType: isBets === 'live' ? 'live-match' : 'pre-match',
                idSport: category.idSport,
                idCategory: category.idCategory,
                categoryName: category.categoryName,
              });
            });

            sport.markets?.forEach((market) => {
              const key = `${market.bet.idSport}-${market.bet.idBet}`;
              if (markets[key]) return;
              markets[key] = true;

              resMarkets.push({
                mType: market.mtype,
                marketName: market.bet.betName,
                idSport: market.id_sport,
                idCategory: market.id_category,
                idTournament: market.id_tournament,
                idMatch: market.id_match,
                idBet: market.bet.idBet,
                team1Name: market.team1_name,
                team2Name: market.team2_name,
                matchDateTimeString: formatDate(market.match_date_time, i18n.language, false),
              });
            });
          });
        }
      }

      let resCasino: any[] = [];
      if (searchType === 'all' || (searchType === 'casino' && Array.isArray(response?.data?.casino?.result?.data))) {
        // search casino games
        // @ts-ignore
        resCasino = response.data.casino.result.data ?? [];
      }

      let resPromotions: any[] = [];
      if (
        searchType === 'all' ||
        (searchType === 'promotions' && Array.isArray(response?.data?.promotions?.result?.data))
      ) {
        // search promotions
        // @ts-ignore
        let items: any[] = response.data.promotions.result.data ?? [];

        items = items.map((item: any) => {
          const div = document.createElement('div');
          div.innerHTML = item.message_primary_short;
          const message_primary_short = div.textContent || div.innerText;

          div.innerHTML = item.message_primary_long;
          const message_primary_long = div.textContent || div.innerText;

          div.innerHTML = item.message_secondary_short;
          const message_secondary_short = div.textContent || div.innerText;

          div.innerHTML = item.message_secondary_long;
          const message_secondary_long = div.textContent || div.innerText;

          return {
            id: item.id,
            image: item.art_bundle?.data?.mid_square_ar11_keycenter,
            art_bundle: item.art_bundle,
            message_primary_short: message_primary_short,
            message_primary_long: message_primary_long,
            message_secondary_short: message_secondary_short,
            message_secondary_long: message_secondary_long,
          };
        });

        resPromotions = items;
      }

      let resLotteries: any[] = [];
      if (
        (searchType === 'all' || searchType === 'lotteries') &&
        window.config.lottoEnabled === '1' &&
        Array.isArray(response?.data?.lotto?.result?.events)
      ) {
        // search promotions
        // @ts-ignore
        let items: any[] = response.data.lotto.result.events ?? [];
        const systems = store.getState().lotto.lottoEvents.systemsMap;

        const system_ids: any = {};

        items = items.filter((item: any) => {
          if (!system_ids[item.system_id]) {
            system_ids[item.system_id] = true;
            return true;
          }
          return false;
        });

        items = items.map((item: any) => {
          const event = { ...item };

          if (systems[event.event_name]) {
            const system: { iso2: string } = systems[event.event_name];

            if ((images as any)[system.iso2] || (images as any)[system.iso2.toLowerCase()]) {
              event.country_flag = (images as any)[system.iso2] || (images as any)[system.iso2.toLowerCase()];
            }
          }

          return event;
        });

        resLotteries = items;
      }

      setState((v: any) => ({
        ...v,
        resCasino,
        resMatches,
        resMarkets,
        resTournaments,
        resCategories,
        resPromotions,
        resLotteries,
        lastUUID: response?.data?.id ?? '',
        searching: false,
        recentSearched,
      }));

      setUIState((v) => {
        const newV = cloneDeep(v);
        newV.matches.showMore = resMatches.length > 5;
        newV.markets.showMore = resMarkets.length > 3;
        newV.tournaments.showMore = resTournaments.length > 3;
        newV.categories.showMore = resCategories.length > 3;
        newV.casino.showMore = resCasino.length > 9;
        newV.promotions.showMore = resPromotions.length > 5;
        newV.lotteries.showMore = resLotteries.length > 5;

        newV.sports = [];
        if (sports.length) {
          // @ts-ignore
          newV.sports = sports;
          newV.selectedSport = -1;
        }

        return newV;
      });
    },
    [state, searchType, i18n.language],
  );

  // handle search query change
  const onChange = React.useCallback((e: React.FormEvent<HTMLInputElement>) => {
    let value = e.target ? (e.target as HTMLInputElement).value : '';

    setQuery(value);

    value = value.trim();

    setUIState(cloneDeep(defaultUIState));

    if (!value) {
      // if the search query is empty, clear state of any previous search results
      setState((v) => ({
        ...v,
        resCasino: [],
        resMatches: [],
        resMarkets: [],
        resTournaments: [],
        resCategories: [],
        resPromotions: [],
        resLotteries: [],
        searching: false,
      }));
    } else {
      setState((v) => ({
        ...v,
        resCasino: [],
        resMatches: [],
        resMarkets: [],
        resTournaments: [],
        resCategories: [],
        resPromotions: [],
        resLotteries: [],
        searching: true,
      }));
    }

    // search is expensive, so we will debounce it
    if (tid.current) clearTimeout(tid.current);
    if (value) {
      tid.current = setTimeout(() => {
        doSearch(value.trim());
      }, 500);
    }
  }, []);

  // the component will enter the UI expanded mode
  const onFocus = React.useCallback((e: React.FormEvent<HTMLInputElement>) => {
    setState((v) => ({ ...v, focused: true }));
  }, []);
  // the component will enter the UI expanded mode
  const toggleFocus = React.useCallback(() => {
    setState((v) => ({ ...v, focused: true }));
    document.querySelector<HTMLInputElement>(`input[name="${searchInput}"]`)?.focus();
  }, []);

  const onDeleteSearch = React.useCallback((e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    e.preventDefault();

    if (e?.currentTarget?.dataset?.index != null) {
      const index = parseInt(e.currentTarget.dataset.index, 10);

      const recentSearched = JSON.parse(localStorage.getItem('recentSearched') || '[]');
      recentSearched.splice(index, 1);
      localStorage.setItem('recentSearched', JSON.stringify(recentSearched.slice(0, maxSearchItems)));
      setState((v) => ({ ...v, recentSearched }));
    }
  }, []);

  const onDeleteAllRecentSearches = React.useCallback((e: React.MouseEvent<HTMLElement>) => {
    localStorage.setItem('recentSearched', '[]');
    setState((v) => ({ ...v, recentSearched: [] }));
  }, []);

  const onSetSearch = React.useCallback((e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    e.preventDefault();

    if (e?.currentTarget?.dataset?.index != null) {
      const index = parseInt(e.currentTarget.dataset.index, 10);

      const recentSearched = JSON.parse(localStorage.getItem('recentSearched') || '[]');
      const value = recentSearched[index];
      if (value) {
        setQuery(value);
        setState((v) => ({
          ...v,
          resCasino: [],
          resMatches: [],
          resMarkets: [],
          resTournaments: [],
          resCategories: [],
          resPromotions: [],
          resLotteries: [],
          searching: true,
        }));
        setUIState(cloneDeep(defaultUIState));
        doSearch(value);
      }
    }
  }, []);

  const onLeagueClick = React.useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      if (e?.currentTarget?.dataset?.mType === 'pre-match') {
        const selectedSport = e?.currentTarget?.dataset?.idSport;

        logSearchBarEvent(e, 'item-click', props, state, deferredQuery);

        dispatch(saveCalendarSelection(0));
        dispatch(prematchFetchSportByDay({ day: 0, idSport: selectedSport, noDays: 31 }));
        setState((v) => ({
          ...v,
          resCasino: [],
          resMatches: [],
          resMarkets: [],
          resTournaments: [],
          resCategories: [],
          resPromotions: [],
          resLotteries: [],
          focused: false,
          searching: false,
          open: false,
        }));
        setQuery('');
        setUIState(cloneDeep(defaultUIState));
      }
    },
    [state],
  );

  // clear the search query but keep the focus. the component will keep the UI expanded mode
  const onClear = React.useCallback(() => {
    document.querySelector<HTMLInputElement>(`input[name="${searchInput}"]`)?.focus();
    const lastUUID = state.lastUUID;
    setQuery('');
    setState((v) => ({ ...v, searching: false, lastUUID: '' }));
    if (lastUUID) {
      missSearch({ uuid: lastUUID });
    }
  }, [state]);

  const onShowMore = React.useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      if (e?.currentTarget?.dataset?.type) {
        const type = e.currentTarget.dataset.type;

        logSearchBarEvent(e, 'show-more', props, state, deferredQuery);

        setUIState((v) => {
          const newV = cloneDeep(v);

          let resMatches = state.resMatches;
          let resMarkets = state.resMarkets;
          let resTournaments = state.resTournaments;
          let resCategories = state.resCategories;

          if (newV.sports.length > 0 && newV.selectedSport > -1) {
            const idSport = (newV.sports[newV.selectedSport] as any).idSport;

            resMatches = state.resMatches.filter((v: any) => v.idSport === idSport);
            resMarkets = state.resMarkets.filter((v: any) => v.idSport === idSport);
            resTournaments = state.resTournaments.filter((v: any) => v.idSport === idSport);
            resCategories = state.resCategories.filter((v: any) => v.idSport === idSport);
          }

          switch (type) {
            case 'matches':
              newV.matches.items += 5;
              newV.matches.showMore = newV.matches.items < resMatches.length;
              break;
            case 'markets':
              newV.markets.items += 3;
              newV.markets.showMore = newV.markets.items < resMarkets.length;
              break;
            case 'tournaments':
              newV.tournaments.items += 3;
              newV.tournaments.showMore = newV.tournaments.items < resTournaments.length;
              break;
            case 'categories':
              newV.categories.items += 3;
              newV.categories.showMore = newV.categories.items < resCategories.length;
              break;
            case 'casino':
              newV.casino.items += 3;
              newV.casino.showMore = newV.casino.items < state.resCasino.length;
              break;
            case 'promotions':
              newV.promotions.items += 5;
              newV.promotions.showMore = newV.promotions.items < state.resPromotions.length;
              break;
            case 'lotteries':
              newV.lotteries.items += 5;
              newV.lotteries.showMore = newV.lotteries.items < state.resLotteries.length;
              break;
          }
          return newV;
        });
      }
    },
    [state],
  );

  const onToggleSport = (e: React.MouseEvent<HTMLElement>) => {
    if (e?.currentTarget?.dataset?.index != null) {
      const index = parseInt(e.currentTarget.dataset.index, 10);
      setUIState((v) => {
        const newV = cloneDeep(v);

        if (newV.selectedSport === index) {
          newV.selectedSport = -1;
        } else {
          newV.selectedSport = index;
        }
        return newV;
      });
    }
  };

  // clear the search query and remove the focus. the component will enter UI minimized mode
  const onCancel = React.useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      const lastUUID = state.lastUUID;
      // clear everything
      setState((v) => ({
        ...v,
        lastUUID: '',
        focused: false,
        searching: false,
        open: false,
        resCasino: [],
        resMatches: [],
        resMarkets: [],
        resTournaments: [],
        resCategories: [],
        resPromotions: [],
        resLotteries: [],
      }));
      setQuery('');
      setUIState(cloneDeep(defaultUIState));

      document.body.classList.remove('search-opened');

      if (e.currentTarget.dataset?.action != null) {
        const action = e.currentTarget.dataset.action;
        let params = {};
        try {
          const tmp = e.currentTarget.dataset.params ? JSON.parse(e.currentTarget.dataset.params) : {};
          if (typeof tmp === 'object') params = tmp;
        } catch (e) {}
        if (lastUUID) {
          logSearchBarEvent(e, 'item-click', props, state, deferredQuery);

          hitSearch({ uuid: lastUUID, action, params });
        }
      } else if (lastUUID) {
        logSearchBarEvent(e, 'cancel', props, state, deferredQuery);

        cancelSearch({ uuid: lastUUID });
        // if it was clicked on a game, without initiating a search
        // it means it was a recently played game, and already logged
      } else if ((e.target as HTMLElement).dataset?.action != null) {
        // no need to log agian
      } else {
        logSearchBarEvent(e, 'cancel', props, state, deferredQuery);
      }
    },
    [state],
  );

  const searchPlaceholder = props.properties.searchPlaceholder;

  const contextValue = React.useMemo(() => {
    const flexOrder = {
      sport: 1,
      casino: 2,
      promotions: 3,
      lotteries: 4,
    };

    if (matches && matches.length > 0) {
      const pathname = matches[matches.length - 1].pathname;
      if (pathname === '/') {
        flexOrder.sport = 1;
        flexOrder.casino = 2;
        flexOrder.promotions = 3;
        flexOrder.lotteries = 4;
      } else if (pathname.includes('/bets/') || pathname.includes('/winnerfun/')) {
        flexOrder.sport = 1;
        flexOrder.casino = 2;
        flexOrder.promotions = 3;
        flexOrder.lotteries = 4;
      } else if (pathname.includes('/casino')) {
        flexOrder.sport = 2;
        flexOrder.casino = 1;
        flexOrder.promotions = 3;
        flexOrder.lotteries = 4;
      } else if (pathname.includes('/promotions')) {
        flexOrder.sport = 2;
        flexOrder.casino = 3;
        flexOrder.promotions = 1;
        flexOrder.lotteries = 4;
      } else if (pathname.includes('/lotto')) {
        flexOrder.sport = 2;
        flexOrder.casino = 3;
        flexOrder.promotions = 4;
        flexOrder.lotteries = 1;
      }
    }

    const contextValue = {
      value: deferredQuery,
      ...state,
      onChange,
      onFocus,
      onClear,
      onCancel,
      toggleFocus,
      toggleOpen,
      searchPlaceholder,
      recentSearched: state.recentSearched
        ?.map((v: string) => ({ value: v, onDeleteSearch, onSetSearch }))
        .slice(0, 10),
      onDeleteAllRecentSearches,
      onLeagueClick,
      isHomepage,
      isDesktop,
      onShowMore,
      uiState: cloneDeep(uiState),
      onToggleSport,
      flexOrder,
      searchType,
    };

    if (uiState.sports.length > 0 && uiState.selectedSport > -1) {
      const idSport = (uiState.sports[uiState.selectedSport] as any).idSport;

      contextValue.resMatches = state.resMatches.filter((v: any) => v.idSport === idSport);
      contextValue.resMarkets = state.resMarkets.filter((v: any) => v.idSport === idSport);
      contextValue.resTournaments = state.resTournaments.filter((v: any) => v.idSport === idSport);
      contextValue.resCategories = state.resCategories.filter((v: any) => v.idSport === idSport);

      contextValue.uiState.matches.showMore = contextValue.uiState.matches.items < contextValue.resMatches.length;
      contextValue.uiState.markets.showMore = contextValue.uiState.markets.items < contextValue.resMarkets.length;
      contextValue.uiState.tournaments.showMore =
        contextValue.uiState.tournaments.items < contextValue.resTournaments.length;
      contextValue.uiState.categories.showMore =
        contextValue.uiState.categories.items < contextValue.resCategories.length;
    }

    return contextValue;
  }, [
    deferredQuery,
    state,
    onChange,
    onFocus,
    onClear,
    onCancel,
    toggleFocus,
    open,
    toggleOpen,
    searchPlaceholder,
    onDeleteSearch,
    onSetSearch,
    onLeagueClick,
    isHomepage,
    isDesktop,
    onShowMore,
    uiState,
    onToggleSport,
    matches,
    onDeleteAllRecentSearches,
  ]);

  if (matches && matches.length > 0) {
    const pathname = matches[matches.length - 1].pathname;
    if (pathname !== '/' && pathname !== '/bets/euro-2024' && isHomepage && !isDesktop) {
      return null;
    }
  }

  // console.log('GeneralSearch[state]', contextValue, contextValue.searching);

  return (
    <ModuleElementDiv className={props.className ?? ''} $styleText={props.styleText}>
      <DataElementContext.Provider value={contextValue}>{componentProps.children}</DataElementContext.Provider>
    </ModuleElementDiv>
  );
};

export default GeneralSearch;
